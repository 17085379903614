<template>
  <section class="app-main" v-if="isRouterAlive">
    <transition name="fade" mode="out-in">
      <!-- <router-view :key="key"></router-view> -->
      <keep-alive>
        <router-view :key="$route.path"></router-view>
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    isRouterAlive() {
      return this.$store.state.isRouterAlive
    }
  }
}
</script>
