<template>
  <div class="notice-component">
    <div class="notice-head">
      <div class="head-check-all">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      </div>
      <div class="head-show-choose">
        <el-switch v-model="switchStatus" active-text="显示已读" @change="handleSwitchChange"></el-switch>
        <div class="choose-btn hover-opa" @click="handleIsRead">标记已读</div>
        <div class="choose-btn hover-opa" @click="handleRemove">删除</div>
      </div>
    </div>
    <div class="notice-body">
      <el-checkbox-group v-model="checkNotice" @change="handleNoticeChange">
        <el-checkbox class="notice-item hover-opa" v-for="(item, index) in form.formResult.list" :key="index" :label="item.id">
          <div class="item-info">
            <div class="info-head">
              <div class="head-title">【{{item.title}}】</div>
              <div class="head-date">发送时间：{{item.publishTime}}</div>
            </div>
            <div class="info-content">{{item.content}}</div>
          </div>
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="notice-pagination">
      <Pagination :form="form" @request-form-list="handleGetNoticeList"></Pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '../pagination/index.vue'
export default {
  name: 'NoticeComponent',
  data() {
    return {
      checkAll: false,
      checkNotice: [],
      switchStatus: false,
      form: {
        formData: {
          isRead: 0,
          title: ''
        },
        pageData: {
          currentPage: 1,
          pageSize: 5,
          sortName: '',
          sortOrder: ''
        },
        formResult: {
          currentPage: 1,
          total: 0,
          list: []
        }
      }
    }
  },
  components: {
    Pagination
  },
  mounted() {
    this.form.pageData.currentPage = 1
    this.form.formResult.list = []
    this.handleGetNoticeList()
  },
  methods: {
    handleCheckAllChange(e) {
      if (e) {
        const arr = []
        for (const item of this.form.formResult.list) {
          arr.push(item.id)
        }
        this.checkNotice = arr
      } else {
        this.checkNotice = []
      }
    },
    handleNoticeChange(e) {
      console.log(e)
    },
    handleSwitchChange(e) {
      this.form.pageData.currentPage = 1
      this.form.formResult.list = []
      this.handleGetNoticeList()
    },
    handleIsRead() {
      if (this.checkNotice.length === 0) {
        return this.$message.warning('请选择想要标记消息')
      }
      this.$api.notice.isRead({ list: this.checkNotice }).then(res => {
        this.$message.success('已读成功')
        this.checkNotice = []
        this.checkAll = false
        this.form.pageData.currentPage = 1
        this.form.formResult.list = []
        this.handleGetNoticeList()
      })
    },
    handleRemove() {
      if (this.checkNotice.length === 0) {
        return this.$message.warning('请选择想要删除消息')
      }
      this.$api.notice.delete({ list: this.checkNotice }).then(res => {
        this.$message.success('删除成功')
        this.checkNotice = []
        this.checkAll = false
        this.form.pageData.currentPage = 1
        this.form.formResult.list = []
        this.handleGetNoticeList()
      })
    },
    handleGetNoticeList() {
      const params = {
        formData: this.form.formData,
        pageData: this.form.pageData
      }
      params.formData.isRead = this.switchStatus * 1
      this.$api.notice.list(params).then(res => {
        this.form.formResult = res.data
        this.form.pageData.currentPage++
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.notice-component{
  .notice-head{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-check-all{}
    .head-show-choose{
      display: flex;
      align-items: center;
      .choose-btn{
        padding: 5px 20px;
        border: 1px solid #727272;
        border-radius: 20px;
        font-size: 24px;
        color: #4F4F4F;
        cursor: pointer;
        margin-left: 20px;
      }
    }
  }
  .notice-body{
    .notice-item{
      padding: 20px;
      border-bottom: 1px solid #686868;
      display: flex;
      align-items: flex-start;
      .item-info{
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        .info-head{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .head-title{
            font-size: 30px;
            color: #4F4F4F;
          }
          .head-date{
            font-size: 24px;
            color: #818181;
          }
        }
        .info-content{
          width: 100%;
          line-height: 30px;
          margin-top: 30px;
          font-size: 24px;
          color: #7D7D7D;
          white-space: normal;
        }
      }
    }
  }
  .notice-pagination{
    padding: 0 20px;
  }
}
</style>

<style lang="scss">
.notice-component{
  .el-checkbox__label, .el-switch__label *{
    line-height: 24px!important;
    font-size: 24px!important;
    font-weight: 400!important;
    color: #4F4F4F!important;
  }
  .el-checkbox{
    display: flex;
    align-items: center;
    .el-checkbox__inner{
      width: 24px;
      height: 24px;
      &::after{
        width: 5px;
        height: 15px;
        left: 8px;
      }
    }
  }
  .el-switch__core{
    width: 48px!important;
    height: 24px!important;
    border-radius: 24px!important;
    &::after{
      width: 20px!important;
      height: 20px!important;
    }
  }
  .notice-body{
    .el-checkbox__label{
      width: 98%;
    }
  }
  .el-checkbox-group .el-checkbox{
    width: 100%!important;
  }
}
</style>
