const pageNum = {
  state: {
    pageNum: 1,
  },
  mutations: {
    setPageNum(num) {
        state.pageNum = num
    },
  },
}

export default pageNum
