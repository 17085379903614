import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import auth from './modules/auth'
import { getParseStorge, setStorgeItem, removeToken } from '../utils/auth'
import api from '../utils/api.js'
import router from '../router'
import pageNum from './modules/tim/pageNum'
// tim
// import tim from 'tim'
import conversation from './modules/tim/conversation'
import group from './modules/tim/group'
import user from './modules/tim/user'
import video from './modules/tim/video'
import friend from './modules/tim/friend'
import blacklist from './modules/tim/blacklist'
import groupLive from './modules/tim/groupLive'
import { Message } from 'element-ui'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    auth,
    conversation,
    group,
    friend,
    blacklist,
    user,
    pageNum,
    groupLive
  },
  getters,
  state: {
    isRouterAlive: true,
    userInfo: getParseStorge('openUniversityUserInfo'), // 用户信息
    showLogin: false, // 显示登录
    activeTab: 'login', // 登录模块
    shopNum: 0, // 购物车数量
    noticeNum: 0, // 通知数量
    // tim
    current: Date.now(),
    intervalID: 0,
    message: undefined
  },
  mutations: {
    SET_USER_INFO: (state, data) => {
      state.userInfo = data
      setStorgeItem('openUniversityUserInfo', data)
    },
    SET_PAGE_NUM: (state, data) => {
      state.pageNum = data
    },
    // 登录模块
    SET_SHOW_LOGIN: (state, data) => {
      state.showLogin = data
    },
    SET_ACTIVE_TAB: (state, data) => {
      state.activeTab = data
    },
    // 购物车数量
    SET_SHOP_NUM: (state, data) => {
      state.shopNum = data
    },
    // 通知数量
    SET_NOITCE_NUM: (state, data) => {
      state.noticeNum = data
    },
    // tim
    startComputeCurrent(state) {
      state.intervalID = setInterval(() => {
        state.current = Date.now()
      }, 500)
    },
    stopComputeCurrent(state) {
      clearInterval(state.intervalID)
      state.intervalID = 0
    },
    showMessage(state, options) {
      if (state.message) {
        state.message.close()
      }
      state.message = Message({
        message: options.message,
        type: options.type || 'success'
      })
    }
  },
  actions: {
    setUserInfo({ commit }, data) {
      commit('SET_USER_INFO', data)
    },
    setPageNum({ commit }, data) {
      commit('SET_PAGE_NUM', data)
    },
    getUserInfo({ commit }, data) {
      api.public.authInfo({ source: 'userInfo' }).then(res => {
        console.log('获取到的用户信息')
        commit('SET_USER_INFO', res.data)
      })
    },
    setShowLogin({ commit }, data) {
      commit('SET_SHOW_LOGIN', data)
    },
    setActiveTab({ commit }, data) {
      commit('SET_ACTIVE_TAB', data)
    },
    // 购物车数量
    setShopNum({ commit }) {
      api.shopping.shopNum({ source: 'shopNum' }).then(res => {
        commit('SET_SHOP_NUM', res.data)
      })
    },
    // 通知数量
    setNoticeNum({ commit }) {
      api.notice.noReadyNum({ source: 'noticeNum' }).then(res => {
        commit('SET_NOITCE_NUM', res.data)
      })
    },
    logout({ commit }) {
      // 先获取登录状态，再处理退出逻辑
      // tim.logout().then(res => { console.log('登出成功') })
      removeToken()
      commit('SET_USER_INFO', null)
      // 手动设置通知数量和购物车数量
      commit('SET_SHOP_NUM', 0)
      commit('SET_NOITCE_NUM', 0)
      router.push({ path: '/home' })
    }
  }
})

export default store
