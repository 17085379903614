// import Vue from 'vue'
import request from './request'
import OSS from 'ali-oss'

const util = {
  telReg(tel) {
    const telReg = new RegExp(/^1[3456789]\d{9}$/)
    return telReg.test(tel)
  },

  idCardReg(num) {
    const idCardReg = new RegExp(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/)
    return idCardReg.test(num)
  },
  // ie ellipsis
  ieEllipsis() {},

  // ie new Date 转换
  ieNewDateGetTime(timestamp) {
    if (!timestamp) return
    let _timestamp = null
    if (/^\d+$/.test(timestamp)) {
      _timestamp = timestamp
    } else if (/-/.test(timestamp)) {
      _timestamp = timestamp.replace(new RegExp(/-/gm), '/')
    } else {
      _timestamp = timestamp
    }
    return new Date(_timestamp).getTime()
  },

  // 时间戳转换
  changeFormat(timestamp, flg, start, end) {
    // timestamp 时间戳
    // flg 分隔符
    // start 转换开始标识 'year', 'month', 'day', 'hour', 'minutes', 'second'
    // end 转换结束标识 'year', 'month', 'day', 'hour', 'minutes', 'second'
    if (!timestamp) return
    let _timestamp = null
    if (/^\d+$/.test(timestamp)) {
      _timestamp = timestamp
    } else if (/-/.test(timestamp)) {
      _timestamp = timestamp.replace(new RegExp(/-/gm), '/')
    } else {
      _timestamp = timestamp// .replace(new RegExp(/-/gm) ,'/')
    }

    // 日期月份/天的显示，如果是1位数，则在前面加上'0'
    const getFormatDate = (arg) => {
      arg = String(arg)
      if (arg === 'undefined' || arg === '') {
        return ''
      }
      var re = arg + ''
      if (re.length < 2) {
        re = '0' + re
      }
      return re
    }

    const getIndexOf = (key, arr, callback) => {
      for (let i = 0; i < arr.length; i++) {
        if (key === arr[i]) {
          callback(i)
        }
      }
    }

    var time = new Date(_timestamp)
    var y = getFormatDate(time.getFullYear()) + (!flg || flg === undefined ? '年' : flg)
    var m = getFormatDate(time.getMonth() + 1) + (!flg || flg === undefined ? '月' : '')
    var d = (!flg || flg === undefined ? '' : flg) + getFormatDate(time.getDate()) + (!flg || flg === undefined ? '日' : '')
    var h = ' ' + getFormatDate(time.getHours()) + ':'
    var mm = getFormatDate(time.getMinutes())
    var s = ':' + getFormatDate(time.getSeconds())

    var timeStr = ''
    var timeKeyArr = ['year', 'month', 'day', 'hour', 'minutes', 'second']
    var timeArr = [y, m, d, h, mm, s]
    getIndexOf(start, timeKeyArr, (_start) => {
      getIndexOf(end, timeKeyArr, (_end) => {
        const _newTimeArr = timeArr.slice(_start, _end + 1)
        for (const item of _newTimeArr) {
          timeStr += item
        }
      })
    })
    return timeStr
  },

  // 将秒转化为时分秒
  formateSeconds(endTime) {
    let secondTime = parseInt(endTime) // 将传入的秒的值转化为Number
    let min = 0 // 初始化分
    let h = 0 // 初始化小时
    let result = ''
    if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
      min = parseInt(secondTime / 60) // 获取分钟，除以60取整数，得到整数分钟
      secondTime = parseInt(secondTime % 60) // 获取秒数，秒数取佘，得到整数秒数
      if (min > 60) { // 如果分钟大于60，将分钟转换成小时
        h = parseInt(min / 60) // 获取小时，获取分钟除以60，得到整数小时
        min = parseInt(min % 60) // 获取小时后取佘的分，获取分钟除以60取佘的分
      }
    }
    if (h > 0) {
      result = `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
    } else {
      result = `${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
    }
    return result
  },

  // 下载
  downloadFile(name, path) {
    const elink = document.createElement('a')
    elink.style.display = 'none'
    elink.href = path + '?response-content-type=application/octet-stream'
    elink.download = name
    // elink.target = '_blank'
    document.body.appendChild(elink)
    elink.click()
    document.body.removeChild(elink)
  },

  // oss资源下载
  downloadOssFunc(name, path) {
    // 这里获取OSS的配置信息
    request({
      url: 'server/api/resourceFile/getOssUploadStsGrantAuthorization',
      method: 'post',
      data: {}
    }).then(response => {
      const ossData = response.data
      // 配置信息
      const client = new OSS({
        region: ossData.region,
        accessKeyId: ossData.accessKeyId,
        accessKeySecret: ossData.accessKeySecret,
        bucket: ossData.bucket,
        stsToken: ossData.securityToken
      })

      const objectName = path.split(window.wtConst.OSS)[1]
      // const type = path.split('.')[path.split('.').length - 1]

      const _downloadUrl = client.signatureUrl(objectName, {
        response: {
          // 'content-disposition': 'attachment; filename="' + name + '.' + type + '"'
          'content-disposition': 'attachment; filename="' + name + '"'
        },
        expires: 3600
      })

      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = _downloadUrl
      elink.download = name.split('.')[0]
      // elink.target = '_blank'
      document.body.appendChild(elink)
      elink.click()
      document.body.removeChild(elink)
    })
  },
  // 过滤图片
  imgFilter(str) {
    if (!str || str.length === 0) {
      return ''
    }
    if (str.indexOf('http') > -1) {
      return str
    }
    return window.wtConst.OSS + str
  },
  // 去除富文本html标签
  removeHtml(str) {
    str = str.replace(/<\/?[^>]*>/g, '') // 去除HTML tag
    str = str.replace(/[ | ]*\n/g, '\n') // 去除行尾空白
    str = str.replace(/ /ig, '') // 去掉
    return str
  },
  // 获取uri参数
  getParams(name) {
    const search = document.location.search
    const pattern = new RegExp('[?&]' + name + '\=([^&]+)', 'g')
    const matcher = pattern.exec(search)
    let items = null
    if (items !== matcher) {
      try {
        items = decodeURIComponent(decodeURIComponent(matcher[1]))
      } catch (e) {
        try {
          items = decodeURIComponent(matcher[1])
        } catch (e) {
          items = matcher[1]
        }
      }
    }
    return items
  }
}

export default util
