import TIM from 'tim-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin'

// 初始化 SDK 实例
const tim = TIM.create({ SDKAppID: window.wtConst.IMSDKAPPID })
window.setLogLevel = tim.setLogLevel
tim.setLogLevel(0)
// 注册 cos
tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })
export default tim
