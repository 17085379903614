<template>
  <div class="pagination">
    <el-pagination
      :current-page="form.formResult.currentPage"
      :page-sizes="form.pageData.pageSizes ? form.pageData.pageSizes :[5, 6, 8, 9, 10, 20, 50]"
      :page-size="form.pageData.pageSize"
      layout="prev, pager, next, total, sizes"
      :total="form.formResult.total"
      @current-change="onPageCurrentChange"
      @size-change="onPageSizeChange"
      :prev-text="'上一页'"
      :next-text="'下一页'"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['form'],
  methods: {
    // 切换页码
    onPageCurrentChange(pageNum) {
      this.form.pageData.currentPage = pageNum
      this.$emit('request-form-list')
      this.$emit('requestFormList')
    },
    // 切换页数
    onPageSizeChange(pageSize) {
      this.form.pageData.currentPage = 1
      this.form.pageData.pageSize = pageSize
      this.$emit('request-form-list')
      this.$emit('requestFormList')
    }
  }
}
</script>
