import request from './request'

const Http = {
  // post请求
  post(obj) {
    const url = obj.url
    const params = obj.params
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: 'post',
        data: params
      }).then(res => {
        resolve(res)
      }).catch(error => {
        console.log('POST-' + url + '-' + error)
        reject(error)
      })
    })
  },

  // post请求
  postOther(obj) {
    const url = obj.url
    const getParams = obj.getParams
    const str = Object.keys(getParams || {}).map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(getParams[key])
    }).join('&')

    return new Promise((resolve, reject) => {
      request({
        url: url + '?' + str,
        method: 'post'
      }).then(res => {
        resolve(res)
      }).catch(error => {
        console.log('GET-' + url + '-' + error)
        reject(error)
      })
    })
  },

  // get请求
  get(obj) {
    const url = obj.url
    const getParams = obj.getParams
    const str = Object.keys(getParams || {}).map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(getParams[key])
    }).join('&')

    return new Promise((resolve, reject) => {
      request({
        url: url + '?' + str,
        responseType: 'arraybuffer',
        method: 'get'
      }).then(res => {
        resolve(res)
      }).catch(error => {
        console.log('GET-' + url + '-' + error)
        reject(error)
      })
    })
  },

  // get请求
  getOther(obj) {
    const url = obj.url
    const getParams = obj.getParams
    const str = Object.keys(getParams || {}).map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(getParams[key])
    }).join('&')

    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: 'get',
        data: str
      }).then(res => {
        resolve(res)
      }).catch(error => {
        console.log('GET-' + url + '-' + error)
        reject(error)
      })
    })
  }
}

export default Http
