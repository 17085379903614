<template>
  <div class="app-wrapper">
    <headbar></headbar>
    <navbar></navbar>
    <div class="main-content" :class="routerActiveList.indexOf(routerActive) > -1 ? 'main-content-index' : ''">
      <div class="main-container">
        <app-main></app-main>
      </div>
    </div>
    <Footerbar></Footerbar>
  </div>
</template>

<script>
import { Headbar, Navbar, Footerbar, AppMain } from '@/modules/layout/components'

export default {
  name: 'layout',
  data() {
    return {
      routerActive: '/home',
      routerActiveList: [
        '/home',
        '/resource/list',
        '/liveClass/list',
        '/liveClass/index',
        '/liveClass/detail',
        '/personal/index',
        '/personal/train',
        '/shoppingCart/index',
        '/shoppingCart/order',
        '/shoppingCart/payOrder',
        '/shoppingCart/gift',
        '/resource/index',
        '/resource/detail',
        '/train/index',
        '/personal/correct',
        '/personal/correctDetail',
        '/train/course',
        '/search/index',
        '/trainShoppingCart/index',
        '/trainShoppingCart/order',
        '/trainShoppingCart/payOrder',
        '/trainShoppingCart/gift'
      ]
    }
  },
  components: {
    Headbar,
    Navbar,
    AppMain,
    Footerbar
  },
  watch: {
    $route(val, oldVal) {
      console.log(val.path)
      this.routerActive = val.path
    }
  },
  mounted() {
    this.routerActive = this.$route.path
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-wrapper {
  width: 100%;
  background-color: #FFFFFF;
  margin: 0 auto;
  .main-content{
    background-color: #fff;
    //height: calc(100vh);
    width: 100vw;
    overflow-y: auto;
    &.main-content-index{
      background-color: #FFFFFF;
    }
    .main-container{
      width: 1400px;
      //height: calc(100vh - 50px);
      margin: 0 auto;
    }
  }
}
</style>
