import Http from './http'

const api = {
  // 公共
  public: {
    authInfo: params => Http.post({ url: '/api/auth/authInfo', params }), // 获取用户信息
    authInfo2: params => Http.post({ url: '/api/user/info'}), // 获取用户信息
    upload: params => Http.post({ url: '/api/file/upload', params }), // 上传文件
    getVodAuth: params => Http.post({ url: '/api/vid/getvideoPlayAuth', params })
  },
  // 登录/注册
  login: {
    centerList: params => Http.post({ url: '/api/collLearningCentre/select', params }), // 学习中心列表
    regist: params => Http.post({ url: '/api/user/regist', params }), // 注册
    loginByPassword: params => Http.post({ url: '/api/auth/loginByPassword', params }), // 账号登录
    sendLoginSmsCode: params => Http.post({ url: '/api/auth/sendLoginSmsCode', params }), // 获取验证码
    loginByCode: params => Http.post({ url: '/api/auth/loginByCode', params }), // 手机号登录
    sendResetPasswordSmsCodeByMobile: params => Http.post({ url: '/api/user/sendResetPasswordSmsCodeByMobile', params }), // 忘记密码获取验证码
    resetPasswordByMobile: params => Http.post({ url: '/api/user/resetPasswordByMobile', params }), // 忘记密码
    // interest: params => Http.post({ url: '/api/collDepartment/interest', params }), // 首次登录专业列表
    // interestSave: params => Http.post({ url: '/api/user/opt/interest', params }) // 首次登录专业保存
    hobbyList: params => Http.post({ url: '/api/hobby/list', params }), // 首次登录专业列表
    hobbySave: params => Http.post({ url: '/api/hobbyUser/add', params }), // 专业保存
    hobbySaveNew: params => Http.post({ url: '/api/hobbyUser/addnew', params }), // 专业保存（新）
    hobbyDelete: params => Http.post({ url: '/api/hobbyUser/delete', params }), // 退出学友圈
    wechatLogin: getParams => Http.postOther({ url: '/api/auth/wxAuth', getParams }), // 微信登录
    wechatBind: params => Http.post({ url: '/api/auth/wxBind', params }) // 微信登录账号绑定
  },
  // 首页
  home: {
    bannerList: params => Http.post({ url: '/api/wsBanner/list', params }), // 轮播图
    bannerClick: params => Http.post({ url: '/api/wsBanner/add/click', params }), // 轮播图点击次数
    newsList: params => Http.post({ url: '/api/wsNews/list', params }), // 通知公告/新闻动态
    courseList: params => Http.post({ url: '/api/course/list', params }), // 免费课程/精品课程/直播课堂
    faculty: params => Http.post({ url: '/api/user/faculty', params }) // 免费课程/精品课程/直播课堂
  },
  // 学校简介
  schoolProfile: {
    schoolDetail: params => Http.post({ url: '/api/collColleges/detail', params }), // 学校简介
    list: params => Http.post({ url: '/api/collDepartment/list', params }), // 学院列表
    detail: params => Http.post({ url: '/api/collDepartment/detail', params }) // 学院详情
  },
  // 新闻资讯
  news: {
    newsDetail: params => Http.post({ url: '/api/wsNews/detail', params }) // 资讯详情
  },
  // 课程
  course: {
    detail: params => Http.post({ url: '/api/courseUserResult/detail', params }), // 课程详情
    getPath: params => Http.post({ url: '/api/courseUserResult/getPath', params }), // 课程详情获取播放地址
    updateCourseStatus: params => Http.post({ url: '/api/courseWareUserResult/updateCourseStatus', params }), // 更新课程学习状态
    studyAdd: params => Http.post({ url: '/api/courseWareUserResult/studyAdd', params }), // 更新课程学习进度
    giveCourse: params => Http.post({ url: '/api/course/give/course', params }), // 赠课列表
    collSpecialtySelect: params => Http.post({ url: '/api/collSpecialty/select', params }), // 赠课专业列表
    getStatus: params => Http.post({ url: '/api/vid/getStatus/' + params }), // 赠课专业列表
    // 课程评论
    talkAdd: params => Http.post({ url: '/api/courseComment/add', params }), // 新增课程评论
    talkList: params => Http.post({ url: '/api/courseComment/list', params }), // 课程评论列表
    talkDetail: params => Http.post({ url: '/api/courseComment/detail', params }), // 课程评论详情
    talkZanAdd: params => Http.post({ url: '/api/courseCommentEvaluate/add', params }), // 课程评论点赞
    talkZanUpdate: params => Http.post({ url: '/api/courseCommentEvaluate/update', params }), // 课程评论取消点赞
    talkDelete: params => Http.post({ url: '/api/courseComment/delete', params }), // 课程评论删除
    talkReport: params => Http.post({ url: '/api/courseCommentReport/add', params }), // 课程评论举报
    // 课程公告
    noticeList: params => Http.post({ url: '/api/courseNotification/list', params }), // 课程公告列表

    // 课程公告
    lndxCourseList: params => Http.post({ url: '/api/lndxcourse/list', params }) // 课程公告列表
  },
  // 购物车
  shopping: {
    shopNum: params => Http.post({ url: '/api/oCart/cart/num', params }), // 购物车商品数量
    cartList: params => Http.post({ url: '/api/oCart/list', params }), // 购物车
    editCart: params => Http.post({ url: '/api/oCart/edit', params }), // 添加购物车
    deleteCart: params => Http.post({ url: '/api/oCart/del', params }), // 删除购物车
    preOrder: params => Http.post({ url: '/api/oOrder/pre/pay', params }), // 预支付
    payOrder: params => Http.post({ url: '/api/oOrder/pay', params }), // 下单
    cartGiftNum: params => Http.post({ url: '/api/oCart/give/num', params }), // 添加赠品个数
    cartGift: params => Http.post({ url: '/api/oCart/edit/give', params }), // 添加赠品
    giveNum: params => Http.post({ url: '/api/oOrder/view/give/num', params }), // 弹窗赠品个数
    myOrder: params => Http.post({ url: '/api/oOrder/myOrder', params }), // 我的订单
    deleteMyOrder: params => Http.post({ url: '/api/oOrder/delete', params }), // 删除我的订单
    oConsumerCoupon: params => Http.post({ url: '/api/oConsumerCoupon/list', params }) // 删除我的订单
  },
  // 支付
  pay: {
    zeroPay: params => Http.post({ url: '/api/pay/zero', params }), // 0元支付
    unionPay: getParams => Http.get({ url: '/unionPay/pcPay', getParams }), // 云闪付
    aliPay: getParams => Http.get({ url: '/alipay/pay', getParams }), // 支付宝支付
    wxPay: params => Http.post({ url: '/wechatpay/unifiedOrder', params }), // 微信支付
    wxPayWebOrder: params => Http.post({ url: '/wechatpay/webOrder', params }), // 微信支付-new
    payStatus: params => Http.post({ url: '/api/pay/status', params }) // 支付结果轮询
  },
  // 研学中心
  study: {
    researchProjectList: params => Http.post({ url: '/api/researchProject/list', params }), // 项目列表
    researchProjectDetail: params => Http.post({ url: '/api/researchProject/detail', params }), // 项目详情
    researchBaseList: params => Http.post({ url: '/api/researchBase/list', params }), // 基地列表
    researchBaseDetail: params => Http.post({ url: '/api/researchBase/detail', params }) // 基地详情
  },
  // 个人中心
  personal: {
    personalUpdate: params => Http.post({ url: '/api/user/update', params }), // 修改个人信息
    personalUpdatePwd: params => Http.post({ url: '/api/auth/update/password', params }), // 修改密码
    personalUpdateUserSmsCodeByMobile: params => Http.post({ url: '/api/user/updateUserSmsCodeByMobile', params }), // 获取旧手机号密码
    personalCheckOldMobile: params => Http.post({ url: '/api/user/usedMobile', params }), // 校验旧手机号
    personalUpdateUserSmsCodeByNewMobile: params => Http.post({ url: '/api/user/updateUserSmsCodeByNewMobile', params }), // 获取新手机号密码
    personalUpdateNewMobile: params => Http.post({ url: '/api/user/newMobile', params }), // 修改手机号
    // 学生端
    personalCourse: params => Http.post({ url: '/api/courseUserResult/my/course', params }), // 我的课程
    personalCommentList: params => Http.post({ url: '/api/courseComment/commentList', params }), // 课程讨论区
    personalCertList: params => Http.post({ url: '/api/userCert/myCert', params }), // 结业证书
    createCert: params => Http.post({ url: '/api/userCert/createCert', params }), // 结业证书
    personalCredit: params => Http.post({ url: '/api/personal/center/myCredit', params }), // 我的学分
    personalCreditTotal: params => Http.post({ url: '/api/personal/center/MyCreditTotal', params }), // 我的学分总分
    submitTaskList: params => Http.post({ url: '/api/workUserResult/submit/list', params }), // 作业提交列表
    stuExamList: params => Http.post({ url: '/api/exam/stuExamList', params }), // 课程考试
    startExam: params => Http.post({ url: '/api/exam/startExam', params }), // 开始考试
    submitExam: params => Http.post({ url: '/api/exam/submitExam', params }), // 考试提交
    examResultList: params => Http.post({ url: '/api/examResult/list', params }), // 考试结果列表
    examResultDetail: params => Http.post({ url: '/api/examResult/queryExamResult', params }), // 考试结果详情
    // 教师端
    teachCourse: params => Http.post({ url: '/api/personal/center/teacher/course', params }), // 任教课程
    teachCourseNoticeAdd: params => Http.post({ url: '/api/courseNotification/add', params }), // 任教课程-发布公告
    teachStudent: params => Http.post({ url: '/api/personal/center/teacher/my/student', params }), // 我的学生列表
    teachStudentDetail: params => Http.post({ url: '/api/personal/center/teacher/my/student/detail', params }), // 我的学生详情
    techWorkReviewList: params => Http.post({ url: '/api/personal/center/teacher/work/review/list', params }), // 作业评阅列表
    workUserResultList: params => Http.post({ url: '/api/workUserResult/list', params }), // 评阅详情列表
    workUserResultDetail: params => Http.post({ url: '/api/workUserResult/detail', params }), // 评阅详情
    updateWork: params => Http.post({ url: '/api/workUserResult/updateWork', params }), // 批改作业
    teachExamList: params => Http.post({ url: '/api/personal/center/teacher/examList', params }), // 课程考试
    teachBacklogList: params => Http.post({ url: '/api/examMarkingManagement/backlogList', params }), // 待批阅列表
    teachBacklogDetail: params => Http.post({ url: '/api/examMarkingManagement/detail', params }), // 待批阅详情
    teachBacklogSubmit: params => Http.post({ url: '/api/examMarkingManagement/markingOperation', params }), // 批阅提交
    offlineUserlist: params => Http.post({ url: '/api/offline/userlist', params }), // 批阅提交
    // 省校报名
    lmTrainClassMyTrain: params => Http.post({ url: '/api/lmTrainClass/myTrain', params }) // 省校报名列表
  },
  // 资源中心
  resource: {
    resourceCenter: params => Http.post({ url: '/api/course/resource/center', params }) // 资源中心
  },
  // 校园文化
  campus: {
    // 学员风采
    activityList: params => Http.post({ url: '/api/activity/list', params }), // 学员风采列表
    activityDetail: params => Http.post({ url: '/api/activity/detail', params }), // 学员风采详情
    activityProductionList: params => Http.post({ url: '/api/activityProduction/list', params }), // 学员风采作品列表 - 登录状态
    activityProductionDetail: params => Http.post({ url: '/api/activityProduction/detail', params }), // 学员风采作品详情 - 登录状态
    activityProductionList1: params => Http.post({ url: '/api/activityProduction/list1', params }), // 学员风采作品列表 - 未登录状态
    activityProductionDetail1: params => Http.post({ url: '/api/activityProduction/detail1', params }), // 学员风采作品详情 - 未登录状态
    activityProductionUpdate: params => Http.post({ url: '/api/activityProduction/update', params }), // 学员风采点赞/投票/评分
    activityProductionMyList: params => Http.post({ url: '/api/activityProduction/myList', params }), // 我的作品列表
    activityProductionScoreList: params => Http.post({ url: '/api/activityProduction/scoreList', params }), // 作品评分列表
    activityProductionGetPath: params => Http.post({ url: '/api/activityProduction/getPath', params }), // 获取视频播放地址
    // 校园活动
    campusList: params => Http.post({ url: '/api/campusCulture/list', params }), // 校园文化列表
    campusDetail: params => Http.post({ url: '/api/campusCulture/detail', params }) // 校园文化详情
  },
  // 省校报名
  train: {
    trainList: params => Http.post({ url: '/api/lmTrainClass/list', params }), // 省校报名列表
    trainDetail: params => Http.post({ url: '/api/lmTrainClass/detail', params }), // 省校报名详情
    isSign: params => Http.post({ url: '/api/lmTrainClass/isSign', params }) // 省校报名是否能报名
  },
  // 作业
  task: {
    taskList: params => Http.post({ url: '/api/work/list', params }), // 作业类别
    taskDetail: params => Http.post({ url: '/api/work/detail', params }), // 作业详情
    taskStart: params => Http.post({ url: '/api/work/start/work', params }), // 开始写作业
    submitWork: params => Http.post({ url: '/api/workUserResult/submitWork', params }), // 提交作业
    logList: params => Http.post({ url: '/api/workUserResult/logList', params }) // 作业历史
  },
  // 老年协会
  old: {
    list: params => Http.post({ url: '/api/seniorCitizenAssociation/list', params }), // 老年协会列表
    detail: params => Http.post({ url: '/api/seniorCitizenAssociation/detail', params }) // 老年协会详情
  },
  // 意见反馈
  feedback: {
    list: params => Http.post({ url: '/api/feedback/list', params }), // 常见问题列表
    add: params => Http.post({ url: '/api/feedback/add', params }) // 意见反馈提交
  },
  // 通知
  notice: {
    noReadyNum: params => Http.post({ url: '/api/message/noReadyNum ', params }), // 未读通知数
    list: params => Http.post({ url: '/api/message/list', params }), // 通知列表
    isRead: params => Http.post({ url: '/api/message/isRead', params }), // 通知标记已读
    detail: params => Http.post({ url: '/api/message/detail', params }), // 通知详情
    delete: params => Http.post({ url: '/api/message/delete', params }) // 通知删除
  },
  auth: {

    loginByPassword: params => Http.post({ url: '/api/authorize/loginByPassword', params }), // 账号登录
    sendLoginSmsCode: params => Http.post({ url: '/api/authorize/sendLoginSmsCode', params }), // 获取验证码
    loginByCode: params => Http.post({ url: '/api/authorize/loginByCode', params }), // 手机号登录
    getUserInfoByFlag: params => Http.post({ url: '/api/authorize/getUserInfoByFlag', params }), // 获取用户信息
    saveUserCentre: params => Http.post({ url: '/api/authorize/saveUserCentre', params }), // 保存用户中心
    loginByFlag: params => Http.post({ url: '/api/authorize/loginByFlag', params }), // 保存用户中心
    getAuthParams: params => Http.post({ url: '/api/authorize/getAuthParams', params }), // 登录参数
    setFlag: params => Http.post({ url: '/api/authorize/setFlag', params }), // 登录参数
    setNouserFlag: params => Http.post({ url: '/api/authorize/setNouserFlag', params }), // 登录参数
    getCode: params => Http.post({ url: '/api/authorize/getCode', params }) // 去登录
  },
  live: {
    polyvUrl: params => Http.post({ url: '/api/polyv/url/' + params }),
    watchToken: params => Http.get({ url: '/api/polyv/get/watchToken/' + params })
  },
  PartyAnd: {
    partyType: params => Http.post({ url: '/api/content/type', params }),
    partyList: params => Http.post({ url: '/api/content/list', params }),
    praise: params => Http.post({ url: '/api/content/praise', params }),
    detail: params => Http.post({ url: '/api/content/detail', params }),
    center: params => Http.post({ url: '/api/course/resource/center', params })
  },
  offline: {
    activityType: params => Http.post({ url: '/api/activityType/list', params }),
    select: params => Http.post({ url: '/api/collSpecialty/select', params }),
    list: params => Http.post({ url: '/api/offline/list', params }),
    detail: params => Http.post({ url: '/api/offline/detail', params }),
    isSign: params => Http.post({ url: '/api/offline/isSign', params }),
    myClass: params => Http.post({ url: '/api/offline/myClass', params }),
    protocol: params => Http.post({ url: '/api/protocol/detail', params }),
    userExcel: params => Http.post({ url: '/api/offline/userExcel', params }),
  }
}

export default api
