import Cookies from 'js-cookie'

const TokenKey = 'Open-University-Web-Token'

export function getToken() {
  // return Cookies.get(TokenKey)
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  // return Cookies.set(TokenKey, token)
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  return localStorage.setItem(TokenKey, '')
}

export function getStorge(key) {
  return Cookies.get(key)
}

export function setStorge(key, value) {
  return Cookies.set(key, value)
}

export function removeStorge(key) {
  return Cookies.remove(key)
}

export function getStorgeItem(key) {
  const value = localStorage.getItem(key)
  if (value === null || value === undefined) {
    return null
  }
  return JSON.parse(value)
}

export function getParseStorge(key) {
  return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null
}

export function setStorgeItem(key, value) {
  return localStorage.setItem(key, JSON.stringify(value))
}

export function sortObj(propertyName) {
  return function(object1, object2) {
    var value1 = object1[propertyName]
    var value2 = object2[propertyName]
    if (value2 < value1) {
      return 1
    } else if (value2 > value1) {
      return -1
    } else {
      return 0
    }
  }
}
