import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import 'babel-polyfill'
import 'swiper/dist/idangerous.swiper.css'
import echarts from 'echarts'
import '@/styles/index.scss' // global css
import App from './App'
import router from './router'
import store from './store'
// tim start
import tim from 'tim'
import TIM from 'tim-js-sdk'
import TWebLive from 'tweblive'
// import VueClipboard from 'vue-clipboard2'
import trtcCalling from './trtc-calling'
import TRTCCalling from 'trtc-calling-js'
import './assets/tim/icon/iconfont.css'
import './assets/tim/icon/tim.css'
import './assets/tim/css/animate.css'
import Avatar from './components/tim/avatar.vue'
// tim end
// video start
import 'video.js/dist/video-js.css'
import videojs from 'video.js'
import 'videojs-contrib-hls'
// video end
/*  注册全局过滤器 */
import filters from '@/utils/filters'
// 全局element 个性样式
import '@/styles/el-special.scss'
// api
import Api from '@/utils/api'
// util
import Util from '@/utils/util'
// util
import WtUtil from '@/utils/wtUtil'
require('es6-promise').polyfill()
require('es6-promise/auto')
Object.keys(filters).forEach(key => {
  Vue.filter(key, (value) => {
    return filters[key][value + ''] || filters[key]['default'] || '-'
  })
})
function isMobile() {
  const userAgentInfo = navigator.userAgent
  const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  const mobileFlag = mobileAgents.some((mobileAgent) => {
    return userAgentInfo.indexOf(mobileAgent) > 0
  })

  return mobileFlag
}

if (isMobile()) {
  window.location.href = 'https://jllnxxzx.com/h5/#/'
}

Vue.use(ElementUI, { locale })
Vue.prototype.$echarts = echarts
Vue.prototype.$api = Api
Vue.prototype.$util = Util
Vue.prototype.$WtUtil = WtUtil
// tim start
window.tim = tim
window.TIM = TIM
window.TRTCCalling = TRTCCalling
window.trtcCalling = trtcCalling
window.store = store
Vue.prototype.$bus = new Vue() // event Bus 用于无关系组件间的通信。
Vue.prototype.tim = tim
Vue.prototype.TIM = TIM
Vue.prototype.TWebLive = TWebLive
Vue.prototype.$store = store
Vue.prototype.trtcCalling = trtcCalling
Vue.prototype.TRTCCalling = TRTCCalling
// tim end
// video start
Vue.prototype.$videojs = videojs
// video end
Vue.config.productionTip = false
Vue.component('avatar', Avatar)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
